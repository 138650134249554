.header_wrapper {
  background-color: var(--brown);
  height: 10vh;
  display: flex;
  align-items: center;
  padding-left: 1vw;
}

.header_wrapper > p {
  font-family: "Indivisible";
  color: var(--light_orange);
  font-weight: 700;
  font-size: 3em;
  text-shadow: 0px 7px 2px rgba(0, 0, 0, 0.6);
}

.header_wrapper > img {
  width: 3em;
  border-radius: 50%;
  margin-right: 1vw;
}

.link_text {
  font-family: "Indivisible";
  color: var(--light_orange);
  font-weight: 400;
  text-decoration-line: none;
}

@media (max-width: 768px) {
  .header_wrapper > img {
    display: none;
  }

  .header_wrapper {
    padding-left:0;
  }

  .header_wrapper > p {
    margin-left: auto;
  }
}

