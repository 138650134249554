.address_bar{
    background-color: var(--brown);
    border-radius: 20px;
    width:100%;
    flex:.2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address_bar > p{
    font-family: 'Indivisible';
    color: var(--light_orange);
    font-size: 2em;
    font-weight: 700;
    text-align: center;
}

.uniswap_link{
    font-family: 'Indivisible';
    color: var(--light_orange);
    font-size: 2em;
    font-weight: 700;
    text-align: center;
}