@import url("https://use.typekit.net/mdp4gsf.css");

:root {
  --bold_orange: #e3c37c;
  --light_orange: #fffdd3;
  --brown: #2f1200;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bold_orange);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  font-family: "indivisible", sans-serif;
  font-weight: 400;
  font-style: normal;
}
