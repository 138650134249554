.grid_wrapper {
  padding: 1vw;
  display: flex;
  height: calc(90vh - 2vw);
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 1vw;
}

.left_grid_wrapper {
  display: flex;
  height: 100%;
  width:30vw;
  background-color: var(--light_orange);
  border: 2px solid var(--brown);
  position: relative;
}

@media (max-width: 768px) {
  .left_grid_wrapper {
    display: none;
  }
}

.left_grid_wrapper > img {
  z-index: 1000;
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 52vh;
  margin: 0;
}

.right_grid_wrapper {
  display: flex;
  height:100%;
  width:70vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 2px solid transparent;

}

.tokenomics_wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--light_orange);
  gap: 2vw;
  flex:1;
  border: 2px solid var(--brown);
  flex-direction: row;
}
