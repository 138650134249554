.chart_container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
  align-items: center;
}

.chart_container > h1 {
  font-family: "Indivisible";
  font-weight: 700;
  color: var(--brown);
}

.chart_container > p {
  font-family: "Indivisible";
  font-weight: 400;
  color: var(--brown);
}
