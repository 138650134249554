.tokenomics_container {
  flex: 0 0 calc(33.33% - 10px);
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.tokenomics_inner {
  flex: 0 0 calc(20%);
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: center;
  padding: 20px;
}

.tokenomics_container > div{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}


.man_step_image {
  display: flex;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.image_container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
  align-items: center;
}

.image_container > h1 {
  font-family: "Indivisible";
  font-weight: 700;
  color: var(--brown);
}
.image_container > p {
  font-family: "Indivisible";
  font-weight: 400;
  color: var(--brown);
  width: 100%;
  text-align: center;
}

.h1 {
  font-family: "Indivisible";
  font-weight: 700;
  color: var(--brown);
}

.text{
  font-family: "Indivisible";
  font-weight: 400;
  color: var(--brown);
  text-align: center;
}